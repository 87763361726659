<template>
  <div class="app">
    <Header/>

    <!-- Content -->
    <div class="container-fluid mb-5" style="background-color: #0077E5; ">
        <div class="container py-5">
            <div class="row">
                <div class="col">
                    <h1 style="color: #FE9800; font-weight: bold; ">About Us</h1>
                    <hr style="border: 2px solid #fff;margin-left: -120px;width: 300px;">
                </div>
            </div>
            <div class="row">
                <div class="col mb-lg-5">
                    <div class="text-justify mx-lg-5">Makuta/Mekutho/Mahkota derived from sanskirt which means ‘Crown’. It’s used by a king to represent his power as well as immortality to their people to loyal from his kingdom. Apart from the symbol of a kingship, Crown in our concept is like a new character will make you pride with your brand, and new insight to your audience. We bring Your crown to life. In Makuta, we design by combining the ideas, design, emotions and client’s culture to create something impressive as your crown that will have a power to create more.</div>
                </div>
            </div>
            <div class="row mx-lg-5">
                <div class="col-12 col-md-6 col-lg-6  mt-3 mt-lg-0">
                    <h3 style="color: #FE9800; font-weight: bold; ">Vission</h3>
                    <div class="text-justify">To be the finest solutions in response to new insight for any client’s industry, focus in Marcomm Services</div>
                </div>
                <div class="col-12 col-md-6 col-lg-6  mt-3 mt-lg-0">
                    <h3 style="color: #FE9800; font-weight: bold; ">Mission</h3>
                    <div class="text-justify">We work as a team have responsibility to Fulfill the visual communication needs and make sure that its directly send to the target audience by providing them a new insight of creative design that will attract them emotionally with the most efficient & effective cost.
                    <br><br>
                    We providing a planned and sustained effort to design your crown (brand) and also building a good relationship with the clients for a better years to come.</div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="container my-4">
        <div class="row ">
            <div class="col ">
                <h1 style="color: #FE9800; font-weight: bold; ">Meet Our Team</h1>
                <hr style="border: 2px solid #0077E5;margin-left: -120px;width: 410px; ">
            </div>
        </div>

        <div class="row mt-3 mb-5 ">
            <div class="col ">
                <p style="text-align: center;">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy
                    nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. </p>
            </div>
        </div>

        <div class="row my-5 ">
            <div class="col-12 col-md-4 col-lg-4 ">
                <div class="card " style="border: 0px;">
                    <img src="../assets/images/our-team/ryan-desta.png" class="img-fluid " alt=" " width="80% ">
                    <div class="card-body ">
                        <ul class="list-group text-center ">
                            <li style="list-style-type: none; font-size: large;color: black; ">
                                Rio Praditya
                            </li>
                            <li class="content-child " style="list-style-type: none; ">
                                @riopraditya21
                            </li>
                            <li class="content-child " style="list-style-type: none; ">
                                Managing Partner
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4 col-lg-4 ">
                <div class="card " style="border: 0px;; ">
                    <center><img src="../assets/images/our-team/agatha-silva.png" class="img-fluid " alt=" " width="80% "></center>
                    <div class="card-body ">
                        <ul class="list-group text-center ">
                            <li style="list-style-type: none; font-size: large;color: black; ">
                                Mitra Komala
                            </li>
                            <li class="content-child " style="list-style-type: none; ">
                                @mitra_qmala
                            </li>
                            <li class="content-child " style="list-style-type: none; ">
                                Software Engineer 
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4 col-lg-4 ">
                <div class="card " style="border: 0px;">
                    <center><img src="../assets/images/our-team/dinda arum.png" class="img-fluid " alt=" " width="80% "></center>
                    <div class="card-body ">
                        <ul class="list-group text-center ">
                            <li style="list-style-type: none; font-size: large;color: black; ">
                                Rieska Safiora
                            </li>
                            <li class="content-child " style="list-style-type: none; ">
                                @rieskasafiora
                            </li>
                            <li class="content-child " style="list-style-type: none; ">
                                Content Manager
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    
    <footer-page/>
  </div>
</template>
  
<script>
import FooterPage from '../components/FooterPage.vue'
import Header from '../components/Header.vue'
export default {
  components: { FooterPage, Header },
  name:'About',
  mounted () {
        window.scrollTo(0, 0)
    }
}
</script>
